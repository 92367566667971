/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
<template>
  <b-card>
    <div v-if="vendor">
      <b-row>
        <b-col cols="12">
          <b-button
            variant="primary"
            size="md"
            class="btn-icon float-right mb-1 mr-1"
            @click="editItem"
          >
            <feather-icon icon="Edit2Icon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <p>Name</p>
        </b-col>
        <b-col cols="4">
          <p>{{ vendor.name }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <p>Email</p>
        </b-col>
        <b-col cols="4">
          <p>{{ vendor.email }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <p>Subject</p>
        </b-col>
        <b-col cols="4">
          <p>{{ vendor.subject }}</p>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
/* eslint-disable */

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
} from 'bootstrap-vue'

export default {
  name: 'EditVendor',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
  },
  props: [
    'vendorId',
  ],
  data() {
    return {
      vendor: '',
      loading: false,
    }
  },
  beforeMount() {
    this.loadVendor()
  },
  methods: {
    async loadVendor() {
      const self = this

      self.loading = true
      await this.$store.dispatch('app/fetchVendor', self.vendorId)
        .then(() => {
          self.vendor = self.$store.state.app.vendor
        })
        .catch(() => {
        })
        .finally(() => {
          self.loading = false
        })
    },
    editItem() {
      this.$router.push({ name: 'editVendor', params: { vendorId: this.vendorId } })
    },
  },
}
</script>
